@import "./props.scss";
@import "./media.scss";

body,
input,
a {
  font-family: "Roboto", sans-serif !important;
}

.appwidth {
  width: 1250px;
  margin: 0 auto;

  @include media("<=xl") {
    width: 1100px;
  }

  @include media("<=lg") {
    width: 970px;
  }

  @include media("<=md") {
    width: auto;
    margin: 0px 25px;
  }

  @include media("<=sm") {
    margin: 0px 20px;
  }
}

// header
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 110px;
  border-bottom: 1px solid #890c8d;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=sm") {
    border: none;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .ls {
      flex: 1;
      .header-logo {
        width: 60px;
        @include media("<=sm") {
          width: 50px;
        }
      }
    }
    .rs {
      .connect-btn {
        background: linear-gradient(45deg, #c213c8, #e069e4);
        min-width: 160px;
        height: 40px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        @include media("<=sm") {
          font-size: 10px;
          min-width: 100px;
          height: 30px;
          border-radius: 6px;
        }
      }
    }
  }
}
input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
// home
.home {
  // padding-top: 130px;
  padding: 250px 0 130px 0;
  position: relative;
  // overflow-x: hidden;
  overflow: hidden;
  @include media("<=sm") {
    padding: 214px 0 30px 0;
  }
  .container {
    display: flex;
    gap: 66px;
    @include media("<=lg") {
      width: 100%;
      padding: 0 20px;
      flex-direction: column;
    }
    .home-ls {
      // flex: 1;
      position: relative;
      .ls_circle {
        width: 406px;
        height: 550px;
        background: rgba(224, 105, 228, 0.53);
        filter: blur(170px);
        position: absolute;
        top: -340px;
        left: -460px;
        z-index: -1;
        @include media("<=sm") {
          left: -360px;
        }
      }
      .swap_card {
        background-color: rgba(28, 0, 87, 0.35);
        border: 1px solid rgba(190, 184, 203, 0.2);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 90%;
        padding: 68px 70px;
        border-radius: 50px;
        z-index: 1;
        @include media("<=sm") {
          padding: 31px 30px;
          border-radius: 24px;
        }
        h1 {
          color: #fff;
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: 700;
          @include media("<=sm") {
            font-size: 12px;
          }
        }
        .field-control {
          width: 100%;
          border: 1px solid #e069e4;
          border-top-right-radius: 30px;
          padding: 30px;
          display: flex;
          align-items: center;
          gap: 20px;
          @include media("<=sm") {
            border-top-right-radius: 14px;
            padding: 16px;
          }
          .field {
            flex: 1;
            .label {
              font-size: 20px;
              font-weight: 700;
              color: #fff;
              margin-bottom: 6px;
              @include media("<=sm") {
                font-size: 12px;
              }
            }
            .input {
              font-size: 26px;
              font-weight: 700;
              color: #fff;
              width: 100%;
              @include media("<=sm") {
                font-size: 16px;
              }
            }
          }
          .btn {
            background: linear-gradient(45deg, #c213c8, #e069e4);
            min-width: 220px;
            height: 56px;
            border-radius: 14px 0px 14px 0px;
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 10px 30px;
            gap: 10px;
            @include media("<=sm") {
              font-size: 12px;
              min-width: 110px;
              height: 28px;
              padding: 6px 8px 6px 18px;
            }

            &.receive {
              padding: 10px 20px 10px 30px;
              @include media("<=sm") {
                padding: 6px 14px 6px 20px;
              }
              .icon {
                width: 30px;
                @include media("<=sm") {
                  width: 18px;
                }
              }
            }
            .icon {
              width: 54px;
              @include media("<=sm") {
                width: 30px;
              }
              &.du {
                width: 26px;
                @include media("<=sm") {
                  width: 18px;
                }
              }
            }
          }
        }
        .arrowDown {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 8px 0;
          svg {
            width: 20px;
            height: 20px;
            color: #ffffff;
          }
        }
        .receive_box {
          width: 100%;
          // height: 130px;
          border: 1px solid #e069e4;
          border-top-right-radius: 30px;
          padding: 30px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          @include media("<=sm") {
            flex-direction: column;
            padding: 20px;
          }
          .receive-ls {
            flex: 1;
            .receive-text {
              font-size: 24px;
              font-weight: 700;
              color: #fff;
              margin-bottom: 8px;
              @include media("<=sm") {
                font-size: 16px;
              }
            }
            .num {
              font-size: 26px;
              font-weight: 700;
              color: #fff;
              width: 100%;
              @include media("<=sm") {
                font-size: 14px;
              }
            }
          }
          .receive-rs {
            display: flex;
            align-items: center;
            .DU-btn {
              background: linear-gradient(45deg, #c213c8, #e069e4);
              width: 100%;
              height: 40px;
              border-top-left-radius: 15px;
              border-bottom-right-radius: 15px;
              font-size: 24px;
              font-weight: 700;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 15px;
              gap: 30px;
              .du-img {
                width: 32px;
              }
            }
          }
        }
        .ded-text {
          margin-top: 42px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          @include media("<=sm") {
            // flex-direction: column;
          }
          .ded_ls {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            @include media("<=sm") {
              font-size: 10px;
            }
          }
          .amount-num {
            display: flex;
            align-items: center;
            gap: 5px;
            .input-add {
              // width: 50px;
              font-size: 20px;
              font-weight: 500;
              color: #fff;
              text-align: end;
              @include media("<=sm") {
                // text-align: start;
                font-size: 10px;
              }
            }
            .input-name {
              color: #fff;
              font-size: 20px;
              font-weight: 500;
              @include media("<=sm") {
                font-size: 10px;
              }
            }
          }
        }
        .exp-text {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          @include media("<=sm") {
            // flex-direction: column;
          }
          .exp_ls {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            @include media("<=sm") {
              font-size: 10px;
            }
          }
          .amount-num {
            display: flex;
            align-items: center;
            gap: 5px;
            .input-add {
              font-size: 20px;
              font-weight: 500;
              color: #fff;
              text-align: end;
              @include media("<=sm") {
                // text-align: start;
                font-size: 10px;
              }
            }
            .input-name {
              color: #fff;
              font-size: 20px;
              font-weight: 500;
              @include media("<=sm") {
                font-size: 10px;
              }
            }
          }
        }
        .swap-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(45deg, #c213c8, #e069e4);
          width: 100%;
          height: 50px;
          margin-top: 20px;
          font-size: 26px;
          font-weight: 500;
          color: #fff;
          border-top-right-radius: 20px;
          @include media("<=sm") {
            font-size: 12px;
            border-top-right-radius: 8px;
            height: 36px;
          }
        }
      }
      .swap-menu {
        position: relative;
        .items {
          position: absolute;
          right: 0;
          z-index: 10;
          margin-top: 10px;
          width: 100%;
          transform-origin: top right;
          border-radius: 6px;
          background: #1c0057;
          border: 1px solid rgba(190, 184, 203, 0.2);
          padding: 8px 0px;
          .item {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            padding: 4px 20px;
            font-size: 16px;
            color: #fff;
            &.active {
              background: linear-gradient(45deg, #c213c8, #e069e4);
            }
            &.du {
              .icon {
                height: 20px;
              }
            }
            .lbl {
              flex: 1;
              text-align: start;
            }
            .icon {
              height: 38px;
            }
          }
        }
      }
    }
    .home-rs {
      position: relative;
      .rs_circle {
        background: rgba(224, 105, 228, 0.53);
        width: 406px;
        height: 589px;
        position: absolute;
        top: -340px;
        right: -300px;
        filter: blur(200px);
        z-index: -1;
        @include media("<=sm") {
          height: 350px;
          width: 280px;
          right: -200px;
          top: -200px;
        }
      }

      .payment-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        .tol_invest {
          width: 100%;
          // height: 140px;
          border: 1px solid #e069e4;
          padding: 30px;
          border-top-right-radius: 40px;
          @include media("<=sm") {
            border-top-right-radius: 20px;
            padding: 20px;
          }
          .invest {
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 10px;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .total-num {
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            @include media("<=sm") {
              font-size: 16px;
            }
          }
        }
        .tol_Ear {
          width: 100%;
          // height: 140px;
          border: 1px solid #e069e4;
          padding: 30px;
          border-top-right-radius: 40px;
          @include media("<=sm") {
            border-top-right-radius: 20px;
            padding: 20px;
          }
         
          .earning {
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 10px;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .btn-box {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            overflow: hidden;
          .total-num {
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            @include media("<=sm") {
              font-size: 16px;
            }
          }
          .btn-withdraw{
            background: linear-gradient(45deg, #c213c8, #e069e4);
            // min-width: 160px;
            height: 40px;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            padding: 0 12px;
          }
        }
        }
        .tol_Bal {
          width: 100%;
          // height: 140px;
          border: 1px solid #e069e4;
          padding: 30px;
          border-top-right-radius: 40px;
          @include media("<=sm") {
            border-top-right-radius: 20px;
            padding: 20px;
          }
          .balance {
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 10px;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .total-num {
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            @include media("<=sm") {
              font-size: 16px;
            }
          }
        }
        .tol_Ref {
          width: 100%;
          // height: 140px;
          border: 1px solid #e069e4;
          padding: 30px;
          border-top-right-radius: 40px;
          @include media("<=sm") {
            border-top-right-radius: 20px;
            padding: 20px;
          }
          .referr {
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 10px;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .total-num {
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            @include media("<=sm") {
              font-size: 16px;
            }
          }
        }
      }
      .link-box {
        border: 1px solid #e069e4;
        width: 100%;
        cursor: pointer;
        padding: 40px;
        border-top-right-radius: 40px;
        margin-top: 25px;
        @include media("<=sm") {
          padding: 22px;
          border-top-right-radius: 22px;
        }
        .text {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 15px;
          .link-name {
            font-size: 22px;
            font-weight: 400;
            color: #fff;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .link_icon {
            svg {
              width: 30px;
              height: 30px;
              @include media("<=sm") {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .url {
          font-size: 28px;
          font-weight: 400;
          color: #e069e4;
          font-family: "Roboto";
          @include media("<=sm") {
            font-size: 16px;
          }
        }
      }
      .analysis {
        margin-top: 40px;
        width: 100%;
        padding: 52px 22px;
        border-radius: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        background-color: rgba(28, 0, 87, 0.35);
        border: 1px solid rgba(190, 184, 203, 0.2);
        @include media("<=sm") {
          padding: 25px 12px;
        }
        .last_time {
          font-size: 25px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 12px;
          z-index: 1;
          @include media("<=sm") {
            font-size: 14px;
          }
        }
        .analysis-bar {
          svg {
            width: 100%;
            // height: 125px;
          }
        }
        .analysis-filed {
          display: flex;
          align-items: center;
          // margin-top: 10px;
          padding: 10px 10px 10px 30px;
          // margin-bottom: 10px;
          @include media("<=sm") {
            padding: 0 5px 0 20px;
          }
          .ls-Analy {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 5px;
            .du-image {
              width: 25px;
              // margin-right: 10px;
              @include media("<=sm") {
                width: 12px;
              }
            }
            .du {
              font-size: 22px;
              font-weight: 700;
              color: #fff;
              @include media("<=sm") {
                font-size: 12px;
              }
            }
            .arrow-right {
              width: 25px;
              height: 25px;
              transform: rotate(-90deg);
              color: #fff;
              @include media("<=sm") {
                width: 15px;
                height: 15px;
              }
            }
            .ls-usdImage {
              width: 40px;
              @include media("<=sm") {
                width: 25px;
              }
            }
            .usdt {
              font-size: 22px;
              font-weight: 700;
              color: #fff;
              @include media("<=sm") {
                font-size: 12px;
              }
            }
          }

          .rs-Analy {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            .rs-image {
              width: 40px;
              @include media("<=sm") {
                width: 25px;
              }
            }
            .chat-usdt-rs {
              font-size: 22px;
              font-weight: 700;
              color: #fff;
              @include media("<=sm") {
                font-size: 12px;
              }
            }
            .arrow-right {
              width: 25px;
              height: 25px;
              transform: rotate(-90deg);
              color: #fff;
              @include media("<=sm") {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .Rp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 35px 0 30px;
          .ls-rp {
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
          .ArrowLeftRight {
            svg {
              width: 35px;
              height: 35px;
              color: #fff;
              @include media("<=sm") {
                width: 20px;
                height: 20px;
              }
            }
          }
          .rs-rp {
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            @include media("<=sm") {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  // history
  .history {
    position: relative;
    margin-top: 25px;
    @include media("<=sm") {
      margin-top: 38px;
    }
    .blur-circle {
      width: 500px;
      height: 400px;
      background: rgba(224, 105, 228, 0.32);
      filter: blur(170px);
      position: absolute;
      right: -200px;
      top: -180px;
      z-index: -1;
    }
    .wrapper {
      .heading-name {
        color: #fff;
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 50px;
        @include media("<=sm") {
          font-size: 30px;
          text-align: center;
          margin-bottom: 25px;
        }
      }
      .table {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-left: 1px solid rgba(255, 255, 255, 0.31);
        border-right: 1px solid rgba(255, 255, 255, 0.31);
        border-bottom: 1px solid rgba(255, 255, 255, 0.31);
        .table-content {
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 2px !important;
            height: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.4);
            border-radius: 5px;
          }
          .table-head {
            display: flex;
            align-items: center;
            height: 90px;
            @include media("<=sm") {
              height: 44px;
            }
            .title {
              color: #1c0057;
              font-size: 22px;
              font-weight: 700;
              &.sr-no {
                background-color: #e069e4;
              }
              @include media("<=sm") {
                font-size: 12px;
              }
            }
            .row {
              background: #fafafb;
            }
          }
        }

        .row {
          flex: 1;
          height: 100%;
          min-width: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media("<=lg") {
            min-width: 220px;
          }
          @include media("<=sm") {
            min-width: 120px;
          }
          &:nth-child(1) {
            max-width: 145px;
            min-width: 145px;
            @include media("<=sm") {
              max-width: 80px;
              min-width: 80px;
            }
          }
        }
        .table-lists {
          .list {
            display: flex;
            height: 80px;
            @include media("<=sm") {
              height: 40px;
            }
            &:nth-child(odd) {
              .row {
                background-color: #2c0a74;
                border-top: 1px solid rgba(255, 255, 255, 0.31);
                border-bottom: 1px solid rgba(255, 255, 255, 0.31);
              }
            }
            .label {
              color: #fff;
              font-size: 20px;
              font-weight: 400;
              @include media("<=sm") {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
